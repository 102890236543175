import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import BackgroundParticles from "./BackgroundParticles";

const Footer = ({ setCurrentPage }) => {
  return (
    <div className="position-relative">
      <footer
        className="text-white text-center py-5 position-relative"
        style={{
          background: "transparent",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <BackgroundParticles />
        <div className="container">
          <h2
            className="display-5 display-md-3 display-lg-2 mb-3"
            style={{
              letterSpacing: "1px",
              color: "white",
              fontWeight: "700",
              marginBottom: "1rem",
              textWrap: "nowrap",
            }}
          >
            &lt;Eduardo Cabral/&gt;
          </h2>

          <h3>
            <p className="mb-3">
              <strong>
                <code
                  style={{
                    fontSize: "1.50rem",
                    background:
                      "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: "bold",
                    textWrap: "nowrap",
                  }}
                >
                  &lt;Software Developer/&gt;
                </code>
              </strong>
            </p>
          </h3>

          <div className="mt-4">
            <a
              href="https://github.com/JackShaw32"
              className="mx-3 fs-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              href="https://linkedin.com/in/raul-eduardo-cabral"
              className="mx-3 fs-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>

          <p
            className="mt-4"
            style={{
              fontSize: "1.2rem",
              color: "rgba(255, 255, 255, 0.75)",
            }}
          >
            &copy; 2025 Eduardo Cabral. All rights reserved.
          </p>
          <button
            onClick={() => setCurrentPage("privacy-policy")}
            style={{
              background: "none",
              border: "none",
              color: "rgba(255, 255, 255, 0.75)",
              cursor: "pointer",
              textDecoration: "underline",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "#007bff";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "rgba(255, 255, 255, 0.75)";
            }}
          >
            Privacy Policy
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
