import React, { useState, useRef } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { Carousel } from "react-bootstrap";
import InnerImageZoom from "react-inner-image-zoom";
import proyectos from "../utils/proyectos";
import { Pagination } from "react-bootstrap";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const Proyectos = ({ descripcion }) => {
  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);
  const proyectosRef = useRef(null);
  const projectsPerPage = 6;
  const totalPages = proyectos
    ? Math.ceil(proyectos.length / projectsPerPage)
    : 0;
  const savedPage = localStorage.getItem("currentPage");
  const initialPage = savedPage ? parseInt(savedPage, 10) : 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [expandedProjects, setExpandedProjects] = useState({});
  const maxLength = 80;

  const toggleExpand = (projectId) => {
    setExpandedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  const handleMouseEnter = (id) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    setTooltipVisible(id);
  };

  const handleMouseLeave = () => {
    setTooltipTimeout(
      setTimeout(() => {
        setTooltipVisible(null);
      }, 100)
    );
  };

  const getTooltipStyle = (detalles) => {
    const maxLines = 14;
    const lineHeight = 1.5;
    const tooltipHeight = detalles.length * lineHeight;

    if (tooltipHeight > maxLines * lineHeight) {
      return {
        maxHeight: "210px",
        overflowY: "auto",
      };
    }
    return { maxHeight: "none", overflowY: "visible" };
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
    localStorage.setItem("currentPage", pageNumber);

    if (proyectosRef.current) {
      proyectosRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const safeProjects = proyectos.map((proyecto) => ({
    ...proyecto,
    imagenes: proyecto.imagenes || [],
    detalles: proyecto.detalles || [],
    tecnologias: proyecto.tecnologias || [],
  }));

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = safeProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  return (
    <section id="Proyects" ref={proyectosRef} className="py-40 text-white mt-3">
      <div className="container text-center mt-5">
        <h2
          className="display-2 display-sm-5 display-md-6 display-lg-3"
          style={{
            fontWeight: "700",
            color: "white",
            marginBottom: "0.5rem",
          }}
        >
          My Works & my Projects
        </h2>
        <h3
          className="display-sm-4 display-md-5 display-lg-2"
          style={{
            background:
              "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: "bold",
            marginBottom: "3rem",
          }}
        >
          Here are some of my recent projects & works.
        </h3>
        <div
          className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
          style={{ overflow: "visible", position: "relative", zIndex: 0 }}
        >
          {currentProjects.map((proyecto) => (
            <motion.div
              key={proyecto.id}
              className="col"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.4,
                ease: "easeOut",
                delay: proyecto.id * 0.2,
              }}
            >
              <div
                className="card shadow-sm rounded-3 position-relative"
                style={{
                  background: "#111111",
                  opacity: "90%",
                  color: "#fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: 1,
                  overflow: "visible",
                }}
              >
                <Carousel
                  fade
                  interval={null}
                  indicators={false}
                  controls={true}
                  nextLabel=""
                  prevLabel=""
                  style={{
                    position: "relative",
                    height: "240px",
                  }}
                >
                  {proyecto.imagenes.map((img, index) => (
                    <Carousel.Item key={index}>
                      <InnerImageZoom
                        loading="lazy"
                        src={img}
                        zoomSrc={img}
                        zoomType="click"
                        zoomPreload={true}
                        zoomScale={0.6}
                        alt={`Imagen ${index + 1} del proyecto ${
                          proyecto.titulo
                        }`}
                        style={{
                          height: "255px",
                          objectFit: "cover",
                          borderRadius: "0.25rem",
                        }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className="card-body text-center">
                  <h5
                    className="card-title mb-3 d-flex justify-content-center align-items-center"
                    style={{ color: "#0dcaf0" }}
                  >
                    {proyecto.titulo}
                    <div
                      style={{ position: "relative" }}
                      onMouseEnter={() => handleMouseEnter(proyecto.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        type="button"
                        aria-label={`Más información sobre ${proyecto.titulo}`}
                        style={{
                          background: "transparent",
                          border: "none",
                          borderRadius: "50%",
                          padding: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <FaInfoCircle
                          style={{ color: "#f4f4f4", fontSize: "15px" }}
                        />
                      </button>
                      {tooltipVisible === proyecto.id && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.3 }}
                          className="tooltip-custom"
                          style={{
                            position: "absolute",
                            bottom: "calc(100% + 10px)",
                            left: "-500%",
                            transform: "translateX(-50%)",
                            zIndex: 9999,
                            backgroundColor: "rgba(0, 0, 0, 0.9)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                            maxWidth: "500px",
                            width: "auto",
                            textAlign: "left",
                            ...getTooltipStyle(proyecto.detalles),
                          }}
                        >
                          <ul
                            className="m-0 p-0"
                            style={{
                              listStyle: "none",
                              fontSize: "10px",
                            }}
                          >
                            {proyecto.detalles.map((detalle, idx) => (
                              <li key={idx} className="mb-1">
                                <span
                                  style={{
                                    backgroundColor: "#32CD32",
                                    color: "black",
                                    borderRadius: "50%",
                                    width: "12px",
                                    height: "12px",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "8px",
                                    marginRight: "5px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ✓
                                </span>
                                {detalle}
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      )}
                    </div>
                  </h5>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#b0b0b0",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleExpand(proyecto.id)}
                  >
                    {expandedProjects[proyecto.id]
                      ? proyecto.descripcion
                      : truncateText(proyecto.descripcion, maxLength)}
                  </p>
                  <div className="d-flex justify-content-center gap-3 mb-3">
                    {proyecto.tecnologias.map((tech, idx) => (
                      <span
                        key={idx}
                        style={{ color: tech.color, fontSize: "16px" }}
                        aria-label={`Tecnología utilizada: ${tech.icon.type.name}`}
                      >
                        {tech.icon}
                      </span>
                    ))}
                  </div>
                  <a
                    href={proyecto.enlace}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn"
                    style={{
                      fontSize: "14px",
                      padding: "8px 16px",
                      borderRadius: "16px",
                      backgroundColor: "#007bff",
                      color: "#f4f4f4",
                      textDecoration: "none",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      transition: "background-color 0.3s ease",
                      fontWeight: "500",
                      lineHeight: "1",
                    }}
                    aria-label={`Ir al proyecto: ${proyecto.titulo}`}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#0056b3";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#007bff";
                    }}
                  >
                    <span style={{ pointerEvents: "none" }}>Deploy</span>
                    <span style={{ pointerEvents: "none" }}>&rarr;</span>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Pagination className="pagination-sm custom-pagination">
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className="custom-page-item"
            >
              ◄
            </Pagination.Prev>

            {[...Array(totalPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => handlePageChange(number + 1)}
                className="custom-page-item"
              >
                {number + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="custom-page-item"
            >
              ►
            </Pagination.Next>
          </Pagination>
        </div>
      </div>

      <style>{`
        .tooltip-custom::-webkit-scrollbar {
          width: 6px;
        }
        .tooltip-custom::-webkit-scrollbar-thumb {
          background-color: #4caf50;
          border-radius: 10px;
        }
        .tooltip-custom::-webkit-scrollbar-thumb:hover {
          background-color: #388e3c;
        }
      `}</style>
    </section>
  );
};

export default Proyectos;
