import React from "react";

const DownloadCVButton = ({ language = "EN", cvLink }) => {
  const downloadLinks = {
    EN: "https://drive.google.com/file/d/14-I-EbjD0LTsCMBJME4kokViRcIoYTCu/view",
    ES: "https://drive.google.com/file/d/1mv9_olQhNh04VolsAuRjAZQk3_RuG9AI/view",
  };

  return (
    <div style={{ textAlign: "center" }}>
      <a
        href={cvLink || downloadLinks[language]}
        download="Eduardo_Cabral_CV.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#444",
          color: "#fff",
          textDecoration: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          fontSize: "0.9rem",
          cursor: "pointer",
          margin: "0 auto",
        }}
      >
        <i
          className="fas fa-download"
          style={{ marginRight: "10px", fontSize: "1rem" }}
        ></i>
        Download CV -
        <strong className={language === "EN" ? "text-primary" : "text-danger"}>
          {language}
        </strong>
      </a>
    </div>
  );
};

export default DownloadCVButton;
