import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactModal from "../Components/ContactModal";
import HamburgerMenu from "../Components/HamburgerMenu";
import { Button } from "react-bootstrap";
import HeaderBackground from "./HeaderBackground";
import "../css/style.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hoverDownload, setHoverDownload] = useState(false);
  const [hoverRequest, setHoverRequest] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <header
      id="Home"
      className="position-relative d-flex flex-column justify-content-center"
      style={{ minHeight: "15vh", paddingTop: "0rem" }}
    >
      <HeaderBackground />

      <div className="container" style={{ position: "relative", zIndex: 1 }}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2
              className="mb-1 text-white"
              style={{
                fontWeight: "700",
                fontSize: "calc(1.5rem + 1vw)",
                letterSpacing: "0.5px",
                marginTop: "1rem",
              }}
            >
              &lt;Eduardo Cabral/&gt;
            </h2>
            <strong>
                <code
                  style={{
                    fontSize: "1.2rem",
                    background:
                      "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: "bold",
                    textWrap: "nowrap",
                  }}
                >
                  &lt;Software Developer/&gt;
                </code>
              </strong>
          </div>

          <div
            className="d-flex align-items-center ms-auto"
            style={{ marginTop: "1rem" }}
          >
            <a
              className="btn d-none d-md-inline-block me-2"
              href="https://drive.google.com/file/d/14-I-EbjD0LTsCMBJME4kokViRcIoYTCu/view"
              download="Eduardo_Cabral_CV.pdf"
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setHoverDownload(true)}
              onMouseLeave={() => setHoverDownload(false)}
              style={{
                fontSize: "1rem",
                padding: "5px 10px",
                color: "white",
                textDecoration: hoverDownload ? "underline" : "none",
              }}
            >
              Download CV
            </a>
            <button
              className="btn d-none d-md-inline-block me-2"
              onClick={handleShowModal}
              onMouseEnter={() => setHoverRequest(true)}
              onMouseLeave={() => setHoverRequest(false)}
              style={{
                fontSize: "1rem",
                padding: "5px 10px",
                color: "white",
                textDecoration: hoverRequest ? "underline" : "none",
              }}
            >
              Request Me
            </button>

            <Button
              variant="light"
              onClick={() => setMenuOpen(!menuOpen)}
              className="ms-auto"
              style={{
                fontSize: "1.2rem",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "none",
                backgroundColor: "transparent",
                padding: "0",
                color: "#fff",
                backdropFilter: "blur(8px)",
                WebkitBackdropFilter: "blur(8px)",
              }}
            >
              {menuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ width: "24px", height: "24px" }}
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                  fill="currentColor"
                  style={{ width: "25px", height: "25px" }}
                >
                  <path
                    d="M4 6h16"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 12h10"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 18h16"
                    stroke="currentColor"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </Button>
          </div>
        </div>
      </div>

      <HamburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

      <ContactModal show={showModal} handleClose={handleCloseModal} />
    </header>
  );
};

export default Header;
