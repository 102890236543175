import {
  FaReact,
  FaNodeJs,
  FaJs,
  FaCss3Alt,
  FaBootstrap,
  FaHtml5,
} from "react-icons/fa";
import {
  SiExpress,
  SiMongodb,
  SiNetlify,
  SiRender,
  SiRailway,
} from "react-icons/si";
import images from "./images";

const proyectos = [
  {
    id: 1,
    titulo: "1/4 de Milla Website",
    descripcion:
      "MERN e-commerce store featuring hoodies and t-shirts, with car and motorcycle themes, fully responsive design.",
    tecnologias: [
      { icon: <FaNodeJs />, color: "#32CD32" },
      { icon: <SiExpress />, color: "#f4f4f4" },
      { icon: <SiMongodb />, color: "#32CD32" },
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
      { icon: <SiRailway />, color: "#f4f4f4" },
    ],
    detalles: [
      "Image Gallery          ",
      "Responsive Design      ",
      "Contact Form           ",
      "Admin Page (ADM)       ",
      "Edit all Banners (ADM) ",
      "Deactivate Users (ADM) ",
      "Create (Products)      ",
      "Delete Products        ",
      "Edit Products          ",
      "Shopping cart          ",
      "Categories and carousel",
      "Online payment         ",
      "(Mercado Pago)         ",
      "Automated shipping     ",
      "10%OFF calculation     ",
      "for (bank transfers)   ",
      "User authentication    ",
      "Order history          ",
      "Account creation       ",
      "Password recovery      ",
      "Secure Authentication  ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "Product catalog        ",
      "(hoodies and t-shirts) ",
      "Domain and Hosting     ",
      "Pixel Facebook         ",
      "API Conversion         ",
    ],
    imagenes: images.unCuartoDeMilla,
    enlace: "https://uncuartodemilla.com",
  },
  {
    id: 2,
    titulo: "E-commerce Website",
    descripcion:
      "Online store for formalwear with secure payment, user authentication, and a fully responsive design for all devices.",
    tecnologias: [
      { icon: <FaNodeJs />, color: "#32CD32" },
      { icon: <SiExpress />, color: "#f4f4f4" },
      { icon: <SiMongodb />, color: "#32CD32" },
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
      { icon: <SiRender />, color: "#f4f4f4" },
    ],
    detalles: [
      "Login                  ",
      "Register               ",
      "Recover Password       ",
      "Order History          ",
      "Admin Page (ADM)       ",
      "Deactivate Users (ADM) ",
      "Create (Products)      ",
      "Delete Products        ",
      "Edit Products          ",
      "Payment Preference     ",
      "Mercado Pago           ",
      "Database (Mongodb)     ",
      "Responsive Design      ",
      "Secure Authentication  ",
    ],
    imagenes: images.ecommerce,
    enlace: "https://web-shop-e-commerce.netlify.app/",
  },
  {
    id: 3,
    titulo: "Personal Portfolio",
    descripcion:
      "Personal portfolio with projects, optimized for unique visuals and interactive features to enhance user experience.",
    tecnologias: [
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
    ],
    detalles: [
      "SPA                    ",
      "Interactive Content    ",
      "Responsive Design      ",
      "Smooth Animations      ",
      "Contact Form           ",
      "Project Gallery        ",
      "About Me Section       ",
      "Social Links           ",
      "Resume Download        ",
      "Privacy Policy         ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
    ],
    imagenes: images.eduardo,
    enlace: "https://eduardocabral.netlify.app/",
  },
  {
    id: 4,
    titulo: "Birthday Event Page",
    descripcion:
      "Animated birthday landing page for a 15th party, with moving background, QR code access, real-time confetti, and email RSVP form.",
    tecnologias: [
      { icon: <FaReact />, color: "#61DBFB" },
      { icon: <FaJs />, color: "#f7df1e" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <SiNetlify />, color: "#f4f4f4" },
    ],
    detalles: [
      "Moving Background      ",
      "(Visible on small screens)",
      "QR Code for Mobile Access",
      "Countdown Timer        ",
      "Real-Time Confetti Effects",
      "Registration Form      ",
      "Email Form Submission  ",
      "Responsive Design      ",
      "Fast Loading           ",
      "Modern UI              ",
      "Multi-Device Support   ",
      "SEO Optimization       ",
    ],
    imagenes: images.evento,
    enlace: "https://zaira-15.netlify.app/",
  },
  {
    id: 5,
    titulo: "Logistics Landing Page",
    descripcion:
      "Transportation landing page with responsive layout, image carousel, and a fully integrated contact form for users.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Images Carousel        ",
      "Responsive Design      ",
      "Contact Form           ",
      "Information Sections   ",
      "Gallery of Images      ",
      "Service Description    ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "Domain and Hosting     ",
    ],
    imagenes: images.omega,
    enlace: "https://www.expresoomega.com",
  },
  {
    id: 6,
    titulo: "Graduation Landing Page",
    descripcion:
      "Graduate apparel brand site with custom designs, responsive modals, and dark/light mode toggle for user choice.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Image Carousel         ",
      "Responsive Design      ",
      "Contact Form           ",
      "Product Gallery        ",
      "Cards with Modals      ",
      "Dark/Light Mode Toggle ",
      "Hamburger Menu         ",
      "Service Description    ",
      "Fast Loading           ",
      "Modern UI              ",
      "SEO Optimization       ",
      "Multi-Device Support   ",
      "Domain and Hosting     ",
    ],
    imagenes: images.roque,
    enlace: "https://www.roqueegresados.com",
  },
  {
    id: 7,
    titulo: "Car Accessories Website",
    descripcion:
      "Minimalist car accessory site with responsive catalog, contact form, and a user-friendly dark/light mode toggle.",
    tecnologias: [
      { icon: <FaHtml5 />, color: "#dd1b16" },
      { icon: <FaCss3Alt />, color: "#1572b6" },
      { icon: <FaBootstrap />, color: "#7952b3" },
      { icon: <FaJs />, color: "#f7df1e" },
    ],
    detalles: [
      "Responsive Design      ",
      "Catalog of Products    ",
      "Contact Form           ",
      "Dark/Light Mode Toggle ",
      "Minimalist Design      ",
      "Product Cards          ",
      "SEO Optimization       ",
      "Fast Loading           ",
      "Modern UI              ",
      "Multi-Device Support   ",
      "Service Description    ",
      "Domain and Hosting     ",
    ],
    imagenes: images.sebacar,
    enlace: "https://www.seba-car.com",
  },
];

export default proyectos;
